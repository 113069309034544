<template>
    <div class="root">
        <v-row class="mt-10">
            <div class="col col-10 offset-1 col-md-6 offset-md-3" align="center">
                <v-card class="mx-auto">
                    <v-card-text>
                    <v-text-field 
                        v-model="searchPhrase" 
                        label="Search by screen name or email" 
                        @keyup="searchByPhrase()"
                        :rules="emailCharacters"
                        required/>
                    </v-card-text>
                </v-card>
            </div>
            <v-col class="col-3" />
        </v-row>

        <v-row class="mt-10">
            <v-col class="col-1">
            </v-col>
            <v-col class="col-10" align="center">
                <v-card elevation="1" outlined v-for="(u, index) in userList" :index="index" :key="index" class="my-2">
                    <v-card-text class=justify-center>
                        <v-row>
                            <v-col cols="5" md="8">
                                <h2>
                                    <a :href="'/' + u.channelName">{{u.channelName}}</a>
                                </h2>
                            </v-col>
                            <v-col v-if="u.friendStatus == 'none'" cols="3" md="2">
                                <a @click="sendInvite(u, index)"> invite </a>
                            </v-col>
                            <v-col v-if="u.friendStatus != 'none'" cols="3" md="2">
                                {{u.friendStatus}}
                            </v-col>
                            <v-col cols="3" md="2">
                                <ListenBell :channelName="u.channelName" :refreshAllFriendData="refreshAllFriendData"/>
                                <FadeAlert ref="fadeAlert" />
                            </v-col>
                            <!-- <v-col cols="2" align="right">
                                Followers:
                            </v-col>
                            <v-col cols="2">
                                {{c.followerCount}}
                            </v-col> -->
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-1" />
        </v-row>
    </div>  
</template>
<script>
import api from '/src/utils/api';

const FriendSearch = {
    name: 'FriendSearch',
    components: {
        ListenBell: () => import("/src/components/ListenBell"),
        FadeAlert: () => import("/src/components/FadeAlert"),
    },
    data() {
        return {
            userList: null,
            searchPhrase: null,
            emailCharacters: [
                //   str => !!str || 'Need more characters to perform search',
                str => /^[A-Za-z@.\d]*$/.test(str) || 'Only letters, numbers, @ and periods are allowed.',
            ],
        };
    },
    mounted() {
        // this.loadInboundFriendRequests();
    },
    methods: {
        refresh() {
            // console.log("FriendSearch refresh");
            // Refresh the last search the user performed to get the most current data.
            this.searchByPhrase(); 
        },
        searchByPhrase() {
            // If someone erases their search phrase, we clear the previous search results.
            if (this.searchPhrase.length == 0) {
                this.userList = null;
                return;
            }
            else {
                api.getCall(`search-friends?searchPhrase=${this.searchPhrase}`).then((userList) => {
                    this.userList = userList;
                });
            }
        },
        sendInvite(user, index) {
            let friendUserId = user.userId;

            api.postAuthRequired('invite-friend', {friendUserId}).then(() => {
                user.friendStatus = 'invited';
                this.refreshAllFriendData();
                this.$refs.fadeAlert[index].showAlert('Get notified about activity!');
            });
        },
    },
    props: ['refreshAllFriendData'],
};

export default FriendSearch;
</script>
